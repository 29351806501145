@import url('https://fonts.googleapis.com/css2?family=Georama:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root{

  --primary-color: #ffb400;
  --secondary-color: #333;
  /* --secondary-color: #6A9BB2; */
  --tertiary-color: #FF8080;
  --quaternary-color: FBB13C;
  --complementary-color: #9FC490;

  --success-color: #2E8B57;
  --danger-color: #E95839;
  --warning-color: #FCC703;
  --edit-color: #1E90FF;
  --disabled-color: #7C7484;

  --text-color: #222;

  --background-color: #b0e0e6;

  --shadow-color: #B7B7B7;

  --module-size: 1rem;
  --default-padding: 6px;
  --default-border-radius: 6px;

  --font-size-title: 20px;

}


body {

  margin: 0;
 /* font-family: 'Mulish', sans-serif; */
  /* font-family: "Nunito Sans", sans-serif; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;

}


code {

  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

}

